import React, { Component } from "react";
import { EventCard } from "../components/EventCard";
import Loader from "../components/Loader";
import sjdc from "../assets/images/sjdc.jpeg";
import sp from "../assets/images/sp.jpeg";
class Home extends Component {
    state = {
        imagesLoaded: false,
    };

    handleImageLoad = () => {
        this.setState({ imagesLoaded: true });
    };

    componentDidMount() {
        const images = [sjdc, sp];
        let loadedImagesCount = 0;

        images.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImagesCount += 1;
                if (loadedImagesCount === images.length) {
                    this.handleImageLoad();
                }
            };
        });
    }

    render() {
        return (
            <div className="d-flex page-container">
                <div className="d-flex flex-column pb-3">
                    <h1 className="page-title p-3 fs-4">Nossos Eventos</h1>
                    {!this.state.imagesLoaded ? (
                        <Loader />
                    ) : (
                        <div className="container mb-3">
                            <div className="d-flex flex-column aling-items-center">
                                <EventCard
                                    image={sp}
                                    url="https://forms.gle/KPrjVgEMVxL1n1DK8"
                                />
                            </div>
                            <hr className="hr" />
                            <div className="d-flex flex-column aling-items-center">
                                <EventCard
                                    image={sjdc}
                                    url="https://forms.gle/1xs2Vkc924B8dEQY6"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Home;
